import React, { useState, useEffect } from "react";
import { getEntries, getEntry } from "../Services/Contentful";
// import { CaseStudyCard } from "./CaseStudyCard";
import { SkillCard } from "./SkillCard";
import bwmount from "../bwmount.jpg";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// import { RICHTEXT_OPTIONS } from "../Services/Contentful/constants";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
// import { Card } from "./Card";
// import { makeSlug } from "../Services/Contentful";

// import Typewriter from "typewriter-effect";


function Home() {
  document.title = "Home";

  


  const [case1, setCase] = useState([]);

  useEffect(() => {
    getEntry("4bWIuw75E69dNqQxjJ0R1N").then((case1) => {
      setCase(case1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const $imageCaseTag = case1.featureImage ? (
    <img
      className="object-contain h-60 w-full"
      src={case1.featureImage.fields.file.url}
      alt={case1.name}
    />
  ) : null;

  const { caselink } = case1;

  const [project, setProject] = useState([]);

  useEffect(() => {
    getEntry("54dftZbhHIZnjn0oc7RWOW").then((project) => {
      setProject(project);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const $imageTag = project.featureImage ? (
    <img
      className="object-contain h-60 w-full"
      src={project.featureImage.fields.file.url}
      alt={project.name}
    />
  ) : null;

  const { name, link, description, id } = project;

  const [skills, setSkills] = useState([]);
  useEffect(() => {
    getEntries("skill", "fields.order").then((skills) => {
      setSkills(skills);
    });
  }, []);

  return (
    <div className="home-page">
      <div className="grid grid-cols-6">
        <div className="hero-text mx-auto col-span-6 sm:col-span-6">
          {/* <img className="home-hero-img" src={bwmount} alt="NZHills" /> */}
          <Parallax
            className="home-hero-img"
            bgImage={bwmount}
            bgImageAlt="nzmount"
            strength={500}
            // blur={{ min: -15, max: 10 }}
          >
            <div className="parallax-inner">
              <div className="justify-center hero-text-wrap">
                <h1 className="home-hero-text mb-5 mt-0 sm:mt-5 text-5xl md:text-6xl lg:text-7xl">
                  Mike Scullin
                </h1>
                <h2 className="text-m md:text-l lg:text-xl">
                  Digital Marketer - Sales Engineer
                </h2>
                <h2 className="text-m md:text-l lg:text-xl mt-2">
                  Technology Enthusiast
                </h2>
              </div>
            </div>
          </Parallax>
        </div>

        <div className="space"></div>

        <div className="home-section home-about max-w-screen-lg mb-20 col-span-6 sm:col-span-6 px-5 lg:px-0" >
          <div className="mid-dark-bg p-5 rounded-xl mb-5 ">
            <div className="font-medium text-lg">Hey, Mike here... </div>

            <p className="light-text">
              You can find out more{" "}
              <Link className="about" to="/about">
                About Me
              </Link>
              , take a look at a few{" "}
              <Link className="projects" to="/projects">
                Personal Projects
              </Link>{" "}
              or check out my CV, Case Studies and Qualifications.
            </p>
          </div>
        </div>

        <div className="features home-section max-w-screen-lg mb-20 col-span-6 sm:col-span-6 grid grid-cols-1 md:grid-cols-2 gap-4 md:grid-flow-col px-5 lg:px-0">
          <div className=" h-full">
            <Link to={"/case-study/" +case1.name+'/'+ case1.id}>
              <div className="h-full">
                <div className="homefeats h-full dark-bg p-5 rounded-xl">
                  <div className="font-medium text-lg">Feature Case Study</div>
                  <div className="font-medium text-m mt-1">{case1.name}</div>
                  <a href={caselink} target="_blank" rel="noopener noreferrer">
                    {case1.link}
                  </a>
                  <p className="light-text">
                    Global maketing campaigns for a world leading forecast
                    provider, Predict Wind.
                  </p>
                  <div className="feat-img mt-2">{$imageCaseTag}</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="h-full">
            <Link to={"/project/" + name + "/" + id}>
              <div className="h-full">
                <div className="homefeats h-full dark-bg p-5 rounded-xl">
                  <div className="font-medium text-lg">Feature Project</div>
                  <div className="font-medium text-m mt-1">{name}</div>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {project.link}
                  </a>
                  <p className="light-text">{description}</p>
                  <div className="feat-img mt-2">{$imageTag}</div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="home-section max-w-screen-lg mb-20 col-span-6 sm:col-span-6">
          <h2 className="pl-0 text-xl md:text-2xl mt-10 mb-10 text-center">
            Skills
          </h2>
          <div className="skills-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-5 lg:px-0 mb-20">
            {skills.map((skill) => (
              <div className="skill-wrap">
                <SkillCard key={skill.id} skill={skill}></SkillCard>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
